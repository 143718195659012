.msl__contact {
  height: 70vh;
  overflow: hidden;
  margin: 8rem 0;
  position: relative;
  background-size: cover;
  background: url('../Services/int.png');
  background-size: cover;
}
.home__contact__bg {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.msl__contact__text {
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.msl__contact__text h2 {
  font-size: 2.2rem;
  letter-spacing: -2px;
  text-align: center;
  font-family: var(--msl-font);
  font-weight: bolder;
  text-transform: uppercase;
}
.msl__contact__text form {
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  width: 35rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-family: var(--msl-font);
  gap: 1rem;
  border: 3px solid rgba(211, 211, 211, 0.161);
}
.form__option {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.form__option input {
  height: 2rem;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 18px;
  border-bottom: 2px solid rgba(211, 211, 211, 0.161);
}
.form__option textarea {
  height: 4rem;
  resize: none;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 18px;
  border-bottom: 2px solid rgba(211, 211, 211, 0.161);
}
.msl__contact__text form button {
  background: linear-gradient(to right, rgb(1, 66, 54)0%, rgb(1, 66, 54) 60%,  rgba(1, 66, 54, 0.431)  100%);
  border: none;
  padding: 0.55rem 1rem;
  font-family: var(--msl-font);
  text-transform: uppercase;
  border-radius: 1rem;
  color: #fff;
  font-weight: bolder;
  font-size: 1.2rem;
  cursor: pointer;
}
@media screen and (max-width: 36rem) {
  .msl__contact__text form {
    width: 98vw;
  }
  .msl__contact {
    height: fit-content;
    padding: 2rem 0;
  }

}