footer {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  min-height: 20rem;
  background-color: rgb(1, 66, 54);
  font-family: var(--msl-font);
}

.footer__logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__logo img {
  max-width: 70%;
  max-height: 70%;
}
.footer__links {
  display: flex;
  flex-direction: column;
  color: #FFF;
  margin-top: 10rem;
}
.footer__links h3 {
  margin-bottom: 10px;
  letter-spacing: -1px;
}

@media screen and (max-width:1000px){
  footer {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 4rem;
  }
  .footer__links {
    display: flex;
    flex-direction: column;
    color: #FFF;
    margin-top: 0rem;
  }
}