.reviews__section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  padding: 3rem;
  margin: 7rem 0;
  color: rgb(53, 53, 53);
  font-family: var(--msl-font);
}
.review__item p{
  margin-bottom: 2rem;
  font-size: 1rem;
  letter-spacing: 2px;
  margin-bottom: 1rem;
}
.review__item h2 {
  font-size: 1.5rem;
  letter-spacing: -1px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
@media screen and (max-width: 1000px) {
  .reviews__section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 3rem 1rem;
    margin: 4rem 0;
    color: rgb(53, 53, 53);
    font-family: var(--msl-font);
  }
}