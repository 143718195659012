.msl__header {
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url('./mslHeader.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__text {
  color: #FFF;
  letter-spacing: -1px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.header__text h1 {
  font-size: 5rem;
  font-family: var(--msl-font);
}
.header__text p {
  font-size: 1.8rem;
  font-family: var(--msl-font);
}
.header__text button {
  font-family: var(--msl-font);
  padding: 10px 20px;
  border: 2px solid #FFF;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  background-color: transparent;
  margin-top: 1rem;
  font-size: 1.4rem;
  color: #FFF;
  text-transform: uppercase;
  transition: 0.2s;
  cursor: pointer;
}
.header__text button:hover {
  backdrop-filter: blur(82px);
  -webkit-backdrop-filter: blur(82px);
  border-radius: 0.4rem;
}
@media screen and (max-width: 1000px) {
  .header__text h1 {
    font-size: 3.5rem;
    line-height: 3.2rem;
    z-index: 10;
    opacity: 1 !important;
  }
  .header__text p {
    font-size: 1.4rem;
    padding: 1rem;
  }
  .msl__header {
    height: 100vh;
    width: 100%;
    position: relative;
    background-image: url('./mslHeader.png');
    background-position: 40%;
    background-size: cover;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header__text {
    /* backdrop-filter: blur(2px); */
    /* -webkit-backdrop-filter: blur(2px); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header__text {
    color: rgb(253, 251, 251);
    letter-spacing: -1px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}