.msl__intro {
  padding: 8rem 2rem;
  font-family: var(--msl-font);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(53, 53, 53);
}
.msl__intro h2 {
  font-size: 3.3rem;
  letter-spacing: -1px;
  margin-bottom: 1rem;
}
.msl__intro p {
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.msl__intro button {
  border: 3px solid rgb(53, 53, 53);
  color: rgb(53, 53, 53);
  padding: 10px 20px;
  background-color: transparent;
  font-size: 1.8rem;
  border-radius: 0.2rem;
  font-family: var(--msl-font);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  transition: .2s;
}
.msl__intro button:hover {
  background-color: rgb(53, 53, 53);
  color: #FFF;
}
@media screen and (max-width: 1000px) {
  .msl__intro h2 {
    font-size: 3rem;
    letter-spacing: -2px;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  .msl__intro button {
    font-size: 1.2rem;
  }
}