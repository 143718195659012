.feature__info {
  color: #3b3b3b;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 4rem 2rem;
  gap: 2rem;
}
.feature__info--container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.feature__info--container span {
  margin-bottom: 1.2rem;
  font-size: 3rem;
}
@media screen and (max-width:1250px){
  .feature__info {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width:550px){
  .feature__info {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .feature__info--container span {
    margin-bottom: 1rem;
    font-size: 3rem;
  }
}