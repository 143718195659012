.siteHub__header {
  height: 100vh;
  padding: 8rem 5rem 0 5rem;
}
.siteHub__header--backdrop {
  border-radius: 3rem;
  height: 100%;
  background-color: #FFFFFF;
  background: 
    linear-gradient(to right, rgba(64, 154, 237) 0%, rgb(161, 205, 245) 60%,  transparent 100%),
    linear-gradient(to left, rgba(64, 154, 237) 15%, rgb(157, 201, 242) 385%, transparent 100%);
  height: 80vh;
}
.siteHub__home--text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  gap: 1rem;
  width: 100%;
  z-index: 3;
}
.siteHub__home--text h1 {
  font-size: 100px;
  line-height: 85px;
  letter-spacing: -.05rem;
  padding: 0rem 3rem;
  color: #FFFFFF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.015));
}
.siteHub__home--text div {
  display: flex;
}
.siteHub__header--backdrop h3 {
  padding: 0rem 3rem;
  color: #FFFFFF;
  letter-spacing: 1px;
}
.siteHub__home--links {
  padding: 0rem 3rem;
  display: flex;
  gap: 1rem;
  width: calc(100% - 4rem);
  justify-content: space-between;
  align-items: center;
}
.siteHub__home--links div {
  gap: 1rem;
  display: flex;
}
.siteHub__home--links a, .siteHub__home--links span {
  /* backdrop-filter: blur(32px); */
  background: rgb(0, 170, 207);
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  max-height: 60px;
  cursor: pointer;
  width: max-content;
  min-width: max-content;
  text-decoration: none;
  color: rgb(10, 14, 18);
  border: 2px solid transparent;
}

.siteHub__home--links a:hover{
  background: rgba(35, 56, 61, 0.3);
}
.siteHub__home--links  span button{
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  text-decoration: none;
  width: 48px;
  height: 28px;
  margin-left: 16px;
  background: rgb(0, 170, 207);
}
.siteHub__home--links  a:nth-child(2){
  background: rgb(40, 49, 58);
  color: #FFFFFF;
}
.siteHub__home--links  a button{
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  text-decoration: none;
  width: 48px;
  height: 28px;
  margin-left: 16px;
  background: rgb(0, 170, 207);
}
.siteHub__home--join {
  max-width: 17rem;
  line-height: 25px;
  background: rgba(35, 56, 61, 0.3);
  padding: 1rem;
  border-radius: 30px;
  color: #FFFFFF;
  letter-spacing: 2px;
}


@media screen and (max-width: 1200px) {
  .siteHub__header {

    padding-top: 5rem;
  }
  .siteHub__home--text h1 {
    font-size: 4rem;
    line-height: 3.6rem;

  }
  .siteHub__home--text h3 {
    letter-spacing: 1px;
    color: #FFFFFF;
    max-width: 60vw;
  }
  .siteHub__home--links {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

}
@media screen and (max-width: 900px) {
  .siteHub__header {
    padding: 0;
    height: unset;
  }
  .siteHub__home--text h1 {
    font-size: 2.8rem;
    line-height: 2.4rem;
    padding: 0rem 2rem;
    text-align: center;
  }
  .siteHub__home--text {
    position: unset;
    justify-content: space-evenly;
    padding: 6rem 0rem;
  }
  .siteHub__home--text h3 {
    padding: 0rem 2rem;
    letter-spacing: 1px;
    font-size: 1rem;
    color: #FFFFFF;
    max-width: unset;
    text-align: center;
  }
  .siteHub__home--links {
    width: 100%;
  }
  .siteHub__home--links a {
    padding: 0.5rem;
  }
  .contentBlock--one img {
    max-height: 15rem;
    opacity: 0.7;
    border-radius: 0.7rem;
  }
  .siteHub__home--links div {
    gap: 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 1rem;
    margin-top: 1rem;
  }
  .siteHub__home--join {
    margin: auto;
  }

  .siteHub__header--backdrop {
    max-width: 100%;
    border-radius: 0;
  }
}
