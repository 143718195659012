.info__sec {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 4rem 2rem;
  gap: 2rem;
}
.info__features {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem;
}
.service__feature {
  display: grid;
  grid-template-columns: 5rem 1fr;
}
.service__feature span {
  font-size: 3rem;
  color: rgb(1, 66, 54);
  margin-top: 2rem;
}
.service__feature div {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  gap: 0.5rem;
  font-family: var(--msl-font);
  color: rgb(58, 58, 58);
}
.service__feature h2 {
  font-size: 2.2rem;
  letter-spacing: -1px;
  text-transform: uppercase;
}
.service__links {
  display: flex;  
  flex-direction: column;
  margin-top: 4rem;
  gap: 1rem;
  font-family: var(--msl-font);
  color: rgb(58, 58, 58);
}
.service__links h2 {
  font-size: 3.3rem;
}
.service__links p {
  font-size: 1.1rem;
  letter-spacing: 1px;
}
.service__links button {
  border: 3px solid rgb(53, 53, 53);
  padding: 8px 16px;
  background-color: transparent;
  font-size: 1.8rem;
  border-radius: 0.2rem;
  font-family: var(--msl-font);
  display: flex;
  align-items: center;
  color: rgb(58, 58, 58);
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  transition: .2s;
}
.service__links button:hover {
  background-color: rgb(53, 53, 53);
  color: #FFF;
}
@media screen and (max-width: 1000px) {
  .info__sec {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem 1rem;
  }
  .info__features {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }
  .service__feature {
    display: grid;
    grid-template-columns: 3rem 1fr;
  }
}