.meet__team {
  width: 100%;
  height: 70vh;
  background-image: url('./meet.png');
  background-size: cover;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.meet__logo {
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meet__logo img {
  max-width: 100%;
  max-height: 80%;
}
.meet__text {
  display: flex;
  justify-content: center;
  color: #FFF;
  font-family: var(--msl-font);
  flex-direction: column;
  z-index: 2;
  padding: 0 2rem;
  gap: 0.5rem;
}
.meet__text h2 {
  text-transform: uppercase;
  font-size: 2.2rem;
}
.meet__text p {
  letter-spacing: 1px;
}
.meet__text button {
  border: 3px solid #FFF;
  padding: 6px 16px;
  background-color: transparent;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 0.2rem;
  font-family: var(--msl-font);
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  transition: .2s;
}
.meet__text button:hover {
  background-color: rgb(53, 53, 53);
  color: #FFF;
}
@media screen and (max-width:1000px) {
  .meet__team {
    background-position: 20%;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
    height: fit-content;
  }
  .meet__logo img {
    max-width: 80%;
    max-height: 15rem;
  }
  .meet__text {
    flex: 2;
    text-align: center;
    align-items: center;
    gap: 1rem;
  }

  .meet__logo {
    width: 100%;
    z-index: 2;
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
