body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto&display=swap');

:root {
  --gradient-text: linear-gradient(89.97deg, #f5f2f8 1.84%, #aef3a3 102.67%);
  --gradient-feature-text: linear-gradient(89.97deg, #5af687 1.84%, #17aa01 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #67f651 -13.86%, #ccdf04 99.55%);
  
  --color-bg: #FFFFFF;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #eff1f3;
  --color-green: #67f651;
  --color-green-dark: #0a3004;
  --color-grey: #3f3f3f;
  --company-bg: #212127;
  --color-subtext: #FF8A71;
  --speed: 500ms;
  --shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --font-family: 'Manrope', sans-serif;
  --msl-font: 'EB Garamond', serif;

}
/* townsend */
/* rgb(40, 49, 58) */

.shake {
  animation: shake 0.2s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-5px, 0);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0);
  }
}
.display {
  width: 100vw;
  height: 100vh;

}
.gradient__bg--light {
  background:radial-gradient(circle at 3% 25%, #021d4f 0%, #4b4c4d 100%);
}
.span__hover {
  cursor: pointer;
  transition: var(--speed);
}
.span__hover:hover {
  opacity: 0.7;
}
.loadingSpinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.35);
  flex-direction: column;
  color: var(--clr-white);
  z-index: 40;
}

.redirecting-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.redirecting-message p{
  font-size: 1.2rem;
  text-align: center;
}
a {
  text-decoration: none;
}

.pop-up {
  box-shadow: var(--shadow);
  border-radius: 0.4rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 300000;
}