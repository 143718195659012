.services__link {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  padding: 5rem;
}
.services__section {
  height: 40rem;
  background-color: grey;
  background-size: cover;
  position: relative;
}
.services__text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #FFF;
  justify-content: center;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  z-index: 2;
}
.services__text span {
  font-size: 3.8rem;
}
.services__text h2 {
  font-size: 2.1rem;
  font-family: var(--msl-font);
  text-transform: uppercase;
  letter-spacing: -1px;
}
.services__text p {
  padding: 1rem 3rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-family: var(--msl-font);
  text-align: center;
}
.services__text button {
  border: 3px solid #FFF;
  padding: 6px 16px;
  background-color: transparent;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 0.2rem;
  font-family: var(--msl-font);
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  transition: .2s;
}
.services__text button:hover {
  background-color: rgb(53, 53, 53);
  color: #FFF;
}
.services__overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .31);
}
.services__section:nth-child(1){
  background-image: url('./ext.png');
  border-top-right-radius: 4rem;
}
.services__overlay__one{
  border-top-right-radius: 4rem;
}
.services__overlay__two{
  border-bottom-left-radius: 4rem;
}

.services__section:nth-child(2){
  border-bottom-left-radius: 4rem;
  background-image: url('./int.png');
}


@media screen and (max-width: 1000px) {
  .services__link {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    padding: 2rem 1rem;
  }
}