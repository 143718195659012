.nav__header  {
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 9999;
  pointer-events: auto;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  font-family: var(--font-family);
}
.siteHub__nav {
  width: 100%;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  color: #3b3b3b;
  box-sizing: border-box;
  padding: 15px 16px;
  height: 100px;
  margin: auto;
  pointer-events: auto;
}
.nav__header a, .nav__header p {
  color: rgb(90, 90, 90) !important;
}
.gpt3__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.gpt3__navbar-links_logo {
  margin-right: 2rem;
}
.gpt3__navbar-links_logo h2{
  font-size: 2.5rem;
  margin: 1rem 0;
  line-height: 30px;
  color: var(--color-text);
}
.gpt3__navbar-links_logo img {
  width: 122.56px;
}

.gpt3__navbar-links_container {
  display: flex;
  flex-direction: row;
}
.gpt3__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p, 
.gpt3__navbar-links_container div span{
  color: #3b3b3b;
  /* font-family: var(--font-family); */
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}
.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  cursor: pointer;
  width: max-content;
  min-width: max-content;
  text-decoration: none;
  color: #3b3b3b;
  border: 2px solid transparent;
}
.gpt3__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}
.gpt3__navbar-menu svg {
  cursor: pointer;
}
.siteHub__navbar-menu{
  display: none;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 100px);
  box-sizing: border-box;
}
.toggle__menu {
  display: none;
}
.siteHub__navbar-menu_container {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    /* background: rgb(20, 26, 32); */
    border-radius: 40px;
    padding: 5px 30px ;
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.2px;
}
.siteHUb-menu_container-links-sign {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin: 16px 0px;
  width: 100%;
}
.siteHUb-menu_container-links-sign a {
  width: 100%;
  max-width: 100%;
  height: 108px;
  line-height: 110%;
  letter-spacing: -0.04em;
  border-radius: 40px;
  padding: 0px 31px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-radius: 38px;
  padding: 12px 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 800;
  font-size: 28px;
  letter-spacing: -0.03em;
  cursor: pointer;
  text-decoration: none;
  color: #3b3b3b;
  border: 2px solid transparent;
  cursor: pointer;
}
.siteHUb-menu_container-links-sign a:hover {
  background-color: var(--clr-orange);
}
.siteHUb-menu_container-links-sign button {
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  text-decoration: none;
  width: 48px;
  height: 28px;
  margin-left: 16px;
  background: rgb(23, 213, 255);
  font-size: 2rem;
}
.about__dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 1rem 0.5rem;
  margin-left: 1rem;
  box-shadow: var(--shadow);
  border-radius: 0.4rem;
}

.about__dropdown a:hover {
  opacity: 0.6;
} 
.siteHub__navbar-menu_container p, .siteHub__navbar-menu_container div span{
  cursor: pointer;
  color: #3b3b3b;
  font-size: 1.5rem;
}
.features__head--info {
  text-decoration: underline;
}

.features__dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 1rem 0.5rem;
  box-shadow: var(--shadow);
  border-radius: 0.4rem;
  gap: 0.3rem;
}
.features__box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.features__box div {
  width: 14rem;
}
.features__dropdown div p{
  margin: 0;
}
.feature__cont {
  width: 90vw;
  margin: auto;
  background-color: #212020;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
  padding: 1rem 4rem;
}
.feature__list {
  display: flex;
  flex: 1;
  padding: 1rem;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
  transition: 0.4s;
  cursor: pointer;
  border-radius: 2rem;
}
.feature__list h2 {
  color: rgba(64, 154, 237);
  font-size: 1.8rem;
  letter-spacing: -2px;
}
.feature__list:hover {
  background-color: #3b3b3b;
}
.feature__list ul {
  list-style: none;
  color: #fff;
}
.feature__list li {
  margin-bottom: 15px;
  transition: 0.3s;
  cursor: pointer;
}
.feature__list li:hover {
  color: rgba(64, 154, 237);
}


@media screen and (max-width: 1050px) {
  .gpt3__navbar-links_container {
      display: none;
  }
  .siteHub__navbar-menu_container {
    display: flex;
  }
  .siteHub__navbar-menu{
    display: flex;
  }
  .gpt3__navbar-sign button {
    display: none;
  }
  .toggle__menu {
      display: flex;
      cursor: pointer;
      margin-left: 0.5rem;
  }
}
@media screen and (max-width: 700px) {
  .gpt3__navbar {
      padding: 2rem;
  }
  .gpt3__navbar-links_logo h2{
    font-size: 2rem;
    margin: 1rem 0;
  }
  .siteHub__navbar-menu_container {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 550px) {
  .gpt3__navbar {
      padding: 2rem;
  }
  .gpt3__navbar-menu_container {
      top: 20px;
  }
  .gpt3__navbar-menu_container-links-sign {
      display: block;
  }
}