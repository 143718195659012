.service__section {
  height: 80vh;
  position: relative;
}
.service__image {
  background-image: url('./action.png');
  position: absolute;
  right: 5rem;
  top: 10%;
  width: 50%;
  height: 70%;
  background-size: cover;
  border-top-right-radius: 3rem;
}
.service__text {
  position: absolute;
  left: 5rem;
  top: 15%;
  width: 50%;
  height: 75%;
  background-color: rgba(1, 66, 54, 0.241) ;
  border-radius: 0.2rem;
  padding-left: 2rem;
  padding-top: 2rem;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: var(--msl-font);
  color: #FFF;
}
.service__text h2 {
  font-size: 3rem;
  line-height: 2.7rem;
  letter-spacing: -1px;
  margin-bottom: 0.85rem;
}
.service__text p {
  font-size: 1.1rem;
  letter-spacing: 2px;
  padding: 1rem;
}
.service__text button {
  max-width: fit-content;
  font-family: var(--msl-font);
  padding: 10px 20px;
  border: 2px solid #FFF;
  backdrop-filter: blur(22px);
  -webkit-backdrop-filter: blur(22px);
  background-color: transparent;
  margin-top: 1rem;
  font-size: 1.4rem;
  color: #FFF;
  text-transform: uppercase;
  transition: 0.2s;
  cursor: pointer;
}
.service__text button:hover {
  backdrop-filter: blur(82px);
  -webkit-backdrop-filter: blur(82px);
  border-radius: 0.4rem;
}

@media screen and (max-width: 1000px) {
  .service__text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 66, 54, 0.241) ;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: var(--msl-font);
    color: #FFF;
  }
  .service__section {
    height: 80vh;
    position: relative;
    overflow: hidden;
  }
}