.siteHub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 7rem;
    z-index: 6;
    background-color: rgb(10, 14, 18);
}
.siteHub-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}
.siteHub-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04rem;
}
.siteHub-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
}
.siteHub-btn p{
    box-sizing: border-box;
    font-family: var(--font-family);
    color: #fff;
    font-size: 18px;
    line-height: 21px;
    word-spacing: 2px; 
}
.siteHub-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}
.siteHub-links div {
    width: 250px;
    margin: 1rem;
}
.siteHub-links_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}
.siteHub-links_logo h2{
    font-family: var(--font-family);
    font-size: 2.5rem;
    margin: 1rem 0;
    line-height: 30px;
    color: var(--color-text);
}
.siteHub-links_logo img {
    width: 10rem;
}
.siteHub-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}
.siteHub-links_div {
    display: flex; 
    justify-content: flex-start;
    flex-direction: column;
}
.siteHub-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 1rem;
}
.siteHub-links_div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
}
.siteHub-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}
.siteHub-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}
@media screen and (max-width: 850px) {
    .siteHub-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
    .siteHub {
        padding: 5rem;
    }
}
@media screen and (max-width: 550px) {
    .siteHub-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
    .siteHub-links div {
        margin: 1rem 0;
    }
    .siteHub-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}
@media screen and (max-width: 550px) {
    .siteHub-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
    .siteHub {
        padding: 2rem;
    }
}